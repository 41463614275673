import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Login from '../screens/Login';
import Home from '../screens/Home';
import Users from '../screens/Users';
import CancelledOrders from '../screens/Orders/CancelledOrders';
import CompletedOrders from '../screens/Orders/CompletedOrders';
import PendingOrders from '../screens/Orders/PendingOrders';
import { AuthContext } from '../context/auth';
import { getStore } from '../utils';
import Reviews from '../screens/WebsiteManager/Reviews';
import ScrapList from '../screens/WebsiteManager/ScrapList';
import Brandings from '../screens/WebsiteManager/Brandings';
import Messages from '../screens/Messages';

function AuthenticatedRoute ({component: Component, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => getStore('user') ? <Component {...props} />: <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}

class Navigation extends Component {
  render() {
    return (
      <AuthContext.Provider>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <AuthenticatedRoute exact path='/home' component={Home} />
            <AuthenticatedRoute exact path='/admin/users' component={Home} />
            <AuthenticatedRoute exact path='/admin/deleted/users' component={Users} />
            <AuthenticatedRoute exact path='/admin/pending/orders' component={PendingOrders} />
            <AuthenticatedRoute exact path='/admin/completed/orders' component={CompletedOrders} />
            <AuthenticatedRoute exact path='/admin/cancelled/orders' component={CancelledOrders} />
            <AuthenticatedRoute exact path='/admin/reviews' component={Reviews} />
            <AuthenticatedRoute exact path='/admin/scrap-list' component={ScrapList} />
            <AuthenticatedRoute exact path='/admin/brandings' component={Brandings} />
            <AuthenticatedRoute exact path='/admin/messages' component={Messages} />
            <Route exact path='*' component={Login} />
          </Switch>
        </Router>
      </AuthContext.Provider>
    )
  }
}

export default Navigation;
