export const Types = {
  LOGIN: 'LOGIN',
  ADD_USER: 'ADD_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
  FORM_SUBMITION_STATUS: 'FORM_SUBMITION_STATUS',
  LOGIN_SUCCESS:'LOGIN_SUCCESS',
  LOGIN_FAIL:'LOGIN_FAIL',
  LOGOUT:'LOGOUT',
  SET_MESSAGE:'SET_MESSAGE',
  REVIEW_SUBMITTED:'REVIEW_SUBMITTED',
  REVIEW_DONE:'REVIEW_DONE',
  ADD_SCRAP:'ADD_SCRAP'
}
