import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Header, Sidebar, Footer } from '../../../components';
import {submitReviewDetail, markReviewDone} from '../../../actions/admin';
export class Reviews extends Component {
  constructor(props) {
    super(props);
    this.addReview = this.addReview.bind(this);
    this.handleShow= this.handleShow.bind(this)
    this.handleClose= this.handleClose.bind(this)
    this.state = {
      reviewer_name: '',
      review_text: '',
      loginStatus: '',
      show: false,
      errors: {
        reviewer_name: 'Enter Name!',
        review_text: 'Enter Review!'
      },
      submitted: false
    }
  }
  
  inputChange = (event) => {
    const { name, value } = event.target; 
    this.setState({ [name]: value });
    this.validationErrorMessage(event);
  }
  validationErrorMessage = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'reviewer_name': 
        errors.reviewer_name = value.length < 1 ? 'Enter User Name' : '';
        break;
      case 'review_text': 
        errors.review_text = value.length < 1 ? 'Enter Password' : '';
        break;
      default:
        break;
    } 
    this.setState({ errors });
  }
  componentDidMount(){
    
  }
  validateForm = (errors) => {
    let valid = true; 
    Object.entries(errors).forEach(item => {
     
      item && item[1].length > 0 && (valid = false)
    }) 
    return valid;
  }

  submitReview = async (event) => {
    this.setState({ submitted: true });
    event.preventDefault();
    if (this.validateForm(this.state.errors)) {
      console.info('Valid Form')
      
      this.props.submitReviewDetail(this.state.review_text,this.state.reviewer_name);
    } else {
      console.log('Invalid Form')
    }
  }
  componentDidUpdate(){
    if(this.props.isLoggedIn==false){
      // this.props.history.push('/login')
    }
    if(this.props.reviewSubmitted==true){
      if(this.state.show==true){
        this.setState({show:false})
      }
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "Review has been added success fully"
      });
      this.props.markReviewDone();
    }
  }
  handleClose () { 
    this.setState({show:false});
  } 
  handleShow () { 
    this.setState({show:true}); 
  }


  addReview(){
    //console.log(this)
    this.StaticExample()
  }

  render() {
    const {reviewer_name, review_text, submitted, errors} = this.state;
    return (
      <div class="container-scroller">
        <Header/>
        <div class="container-fluid page-body-wrapper">
          <Sidebar/>
          <div class="main-panel">
            <div class="content-wrapper">
             
              <div class="page-header">
              <h3 class="page-title"> Reviews </h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Tables</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Data table</li>
                </ol>
              </nav>
            </div>
            <div class="card">
              <div class="card-body">
                <div className='row'>
                  <div className='col-md-2'>
                    
                    <button class="btn btn-outline-primary" onClick={this.handleShow}>
                      Add Review
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div id="order-listing_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer"><div class="row"><div class="col-sm-12 col-md-6"><div class="dataTables_length" id="order-listing_length"><label>Show <select name="order-listing_length" aria-controls="order-listing" class="custom-select custom-select-sm form-control"><option value="5">5</option><option value="10">10</option><option value="15">15</option><option value="-1">All</option></select> entries</label></div></div><div class="col-sm-12 col-md-6"><div id="order-listing_filter" class="dataTables_filter"><label><input type="search" class="form-control" placeholder="Search" aria-controls="order-listing"/></label></div></div></div><div class="row"><div class="col-sm-12"><table id="order-listing" class="table dataTable no-footer" aria-describedby="order-listing_info">
                      <thead>
                        <tr><th class="sorting sorting_asc" tabindex="0" aria-controls="order-listing" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Order #: activate to sort column descending" style={{width: "56.0469px"}}>Order #</th><th class="sorting" tabindex="0" aria-controls="order-listing" rowspan="1" colspan="1" aria-label="Purchased On: activate to sort column ascending" style={{width: "102.172px"}}>Purchased On</th><th class="sorting" tabindex="0" aria-controls="order-listing" rowspan="1" colspan="1" aria-label="Customer: activate to sort column ascending" style={{width: "70.5625px"}}>Customer</th><th class="sorting" tabindex="0" aria-controls="order-listing" rowspan="1" colspan="1" aria-label="Ship to: activate to sort column ascending" style={{width: "56.8125px"}}>Ship to</th><th class="sorting" tabindex="0" aria-controls="order-listing" rowspan="1" colspan="1" aria-label="Base Price: activate to sort column ascending" style={{width: "78.25px"}}>Base Price</th><th class="sorting" tabindex="0" aria-controls="order-listing" rowspan="1" colspan="1" aria-label="Purchased Price: activate to sort column ascending" style={{width: "116.672px"}}>Purchased Price</th><th class="sorting" tabindex="0" aria-controls="order-listing" rowspan="1" colspan="1" aria-label="Status: activate to sort column ascending" style={{width: "66.2031px"}}>Status</th><th class="sorting" tabindex="0" aria-controls="order-listing" rowspan="1" colspan="1" aria-label="Actions: activate to sort column ascending" style={{width: "62.2812px"}}>Actions</th></tr>
                      </thead>
                      <tbody>
                      <tr class="odd">
                          <td class="sorting_1">1</td>
                          <td>2012/08/03</td>
                          <td>Edinburgh</td>
                          <td>New York</td>
                          <td>$1500</td>
                          <td>$3200</td>
                          <td>
                            <label class="badge badge-info">On hold</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr><tr class="even">
                          <td class="sorting_1">2</td>
                          <td>2015/04/01</td>
                          <td>Doe</td>
                          <td>Brazil</td>
                          <td>$4500</td>
                          <td>$7500</td>
                          <td>
                            <label class="badge badge-danger">Pending</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr><tr class="odd">
                          <td class="sorting_1">3</td>
                          <td>2010/11/21</td>
                          <td>Sam</td>
                          <td>Tokyo</td>
                          <td>$2100</td>
                          <td>$6300</td>
                          <td>
                            <label class="badge badge-success">Closed</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr><tr class="even">
                          <td class="sorting_1">4</td>
                          <td>2016/01/12</td>
                          <td>Sam</td>
                          <td>Tokyo</td>
                          <td>$2100</td>
                          <td>$6300</td>
                          <td>
                            <label class="badge badge-success">Closed</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr><tr class="odd">
                          <td class="sorting_1">5</td>
                          <td>2017/12/28</td>
                          <td>Sam</td>
                          <td>Tokyo</td>
                          <td>$2100</td>
                          <td>$6300</td>
                          <td>
                            <label class="badge badge-success">Closed</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr><tr class="even">
                          <td class="sorting_1">6</td>
                          <td>2000/10/30</td>
                          <td>Sam</td>
                          <td>Tokyo</td>
                          <td>$2100</td>
                          <td>$6300</td>
                          <td>
                            <label class="badge badge-info">On-hold</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr><tr class="odd">
                          <td class="sorting_1">7</td>
                          <td>2011/03/11</td>
                          <td>Cris</td>
                          <td>Tokyo</td>
                          <td>$2100</td>
                          <td>$6300</td>
                          <td>
                            <label class="badge badge-success">Closed</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr><tr class="even">
                          <td class="sorting_1">8</td>
                          <td>2015/06/25</td>
                          <td>Tim</td>
                          <td>Italy</td>
                          <td>$6300</td>
                          <td>$2100</td>
                          <td>
                            <label class="badge badge-info">On-hold</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr><tr class="odd">
                          <td class="sorting_1">9</td>
                          <td>2016/11/12</td>
                          <td>John</td>
                          <td>Tokyo</td>
                          <td>$2100</td>
                          <td>$6300</td>
                          <td>
                            <label class="badge badge-success">Closed</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr><tr class="even">
                          <td class="sorting_1">10</td>
                          <td>2003/12/26</td>
                          <td>Tom</td>
                          <td>Germany</td>
                          <td>$1100</td>
                          <td>$2300</td>
                          <td>
                            <label class="badge badge-danger">Pending</label>
                          </td>
                          <td>
                            <button class="btn btn-outline-primary">View</button>
                          </td>
                        </tr></tbody>
                    </table></div></div><div class="row"><div class="col-sm-12 col-md-5"><div class="dataTables_info" id="order-listing_info" role="status" aria-live="polite">Showing 1 to 10 of 10 entries</div></div><div class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="order-listing_paginate"><ul class="pagination"><li class="paginate_button page-item previous disabled" id="order-listing_previous"><a href="#" aria-controls="order-listing" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="order-listing" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next disabled" id="order-listing_next"><a href="#" aria-controls="order-listing" data-dt-idx="2" tabindex="0" class="page-link">Next</a></li></ul></div></div></div></div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <Footer/>
            
 
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Review</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form>
                <div class="form-group">
                  <label for="recipient-name" value={reviewer_name} class="col-form-label">Name:</label>
                  <input type="text" class="form-control" id="reviewer_name" name="reviewer_name" onChange={(e) => { this.inputChange(e)} }/>
                  { submitted && errors.reviewer_name.length > 0 &&  <span className='error'>{errors.reviewer_name}</span>}
                </div>
                <div class="form-group">
                  <label for="message-text" class="col-form-label">Review:</label>
                  <textarea class="form-control" value={review_text} id="review_text" name="review_text" onChange={(e) => { this.inputChange(e)} }></textarea>
                  { submitted && errors.review_text.length > 0 &&  <span className='error'>{errors.review_text}</span>}
                </div>
                <div class="form-group">
                  <label for="message-text" class="col-form-label">Photo:</label>
                  <input type="file" class="form-control" id="reviwer_photo"/>
                </div>
              </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.submitReview}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isLoggedIn,
  reviewSubmitted:state.admin.reviewSubmitted
});
const mapDispatchToProps = {
  submitReviewDetail,
  markReviewDone
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reviews));
