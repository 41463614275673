import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
// import { ActionCreators } from '../../actions/profile';
import { login, loginFailure } from '../../actions/auth';
import { getStore } from '../../utils';
import './style.css';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errors: {
        username: 'Enter User Name!',
        password: 'Enter Password!'
      },
      loginStatus: '',
      submitted: false
    }
  }
  componentDidMount() {
    console.log("hello component update", this.props)
    if(this.props.isLoggedIn==true){
      this.props.history.push('/home')
    }
  }
  componentDidUpdate(prevProps, prevState) {
    
  }
  inputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.validationErrorMessage(event);
  }

  validationErrorMessage = (event) => {
    const { name, value } = event.target;
    console.log( name, value ,'askdjalksjdkljaslkd')
    let errors = this.state.errors;
    switch (name) {
      case 'username': 
        errors.username = value.length < 1 ? 'Enter User Name' : '';
        break;
      case 'password': 
        errors.password = value.length < 1 ? 'Enter Password' : '';
        break;
      default:
        break;
    }
    console.log(errors,'errors')
    this.setState({ errors });
  }

  validateForm = (errors) => {
    let valid = true;
    console.log('errors',this.state)
    Object.entries(errors).forEach(item => {
      console.log(item)
      item && item[1].length > 0 && (valid = false)
    })
    console.log(valid)
    return valid;
  }

  loginForm = async (event) => {
    this.setState({ submitted: true });
    event.preventDefault();
    if (this.validateForm(this.state.errors)) {
      console.info('Valid Form')
      const user = getStore('user')
      console.log("user",user)
      if (user) {
        //this.props.dispatch(ActionCreators.login(user));
        this.props.history.push('/home')
      } else {
        this.props.login(this.state.username,this.state.password);
        //this.setState({ loginStatus: 'Login Failed! Invalid Username and Password'})
      }
    } else {
      console.log('Invalid Form')
    }
  }

  render() {
    const { username, password, errors, submitted, loginStatus } = this.state;
    return (
      <div className="pagecenter loginForm">
        
        <div class="container-scroller">
          <div class="container-fluid page-body-wrapper full-page-wrapper">
            <div class="content-wrapper d-flex align-items-center auth">
              <div class="row flex-grow">
                <div class="col-lg-4 mx-auto">
                  <div class="auth-form-light text-left p-5">
                    <div class="brand-logo">
                      <img src="../../assets/images/logo.svg"/>
                    </div>
                    <h4>Hello! let's get started</h4>
                    <h6 class="font-weight-light">Sign in to continue.</h6>
                    <form class="pt-3">
                      <div class="form-group">
                      <input type="text" value={username} name="username" onChange={(e) => { this.inputChange(e)} } className="form-control" id="username" placeholder="User Name" />
                      { submitted && errors.username.length > 0 &&  <span className='error'>{errors.username}</span>}
                      </div>
                      <div class="form-group">
                      <input type="password" value={password} autoComplete="on" name="password" onChange={(e) => { this.inputChange(e)} } className="form-control" id="password" placeholder="Password" />
                      { submitted && errors.password.length > 0 &&  <span className='error'>{errors.password}</span>}
                      </div>
                      <div className="row">
                        <div className="col-sm-12 center mt-1">
                          { submitted && loginStatus.length > 0 &&  <span className='error'>{loginStatus}</span>}
                        </div>
                      </div>
                      <div class="mt-3">
                        <a class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" onClick={this.loginForm}>SIGN IN</a>
                      </div>
                      <div class="my-2 d-flex justify-content-between align-items-center">
                        <div class="form-check">
                          <label class="form-check-label text-muted">
                            <input type="checkbox" class="form-check-input"/> Keep me signed in </label>
                        </div>
                        
                      </div>
                       
                    </form>
                  </div>
                </div>
              </div>
            </div>
       
          </div>
       
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = {
  login,
  loginFailure,
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Login));
