import { Types } from "../constants/actionTypes";
  
  const user = JSON.parse(localStorage.getItem("user"));
  
  const initialState ={ reviewSubmitted: false, reviews:[] };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case Types.REVIEW_SUBMITTED:
        return {
            ...state,
            reviewSubmitted: true,
            reviews: [...state.reviews, payload.reviews.data]
        };
      case Types.ADD_SCRAP:
          return {
              ...state,
              reviewSubmitted: true
          };
    case Types.REVIEW_DONE:
        return {
            ...state,
            reviewSubmitted: false
        };
      default:
        return state;
    }
  }