import { Types } from "../constants/actionTypes";
  
import AuthService from "../services/auth.service";
  
  export const register = (username, email, password) => (dispatch) => {
    return AuthService.register(username, email, password).then(
      (response) => {
        dispatch({
          type: Types.REGISTER_SUCCESS,
        });
  
        dispatch({
          type: Types.SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: Types.REGISTER_FAIL,
        });
  
        dispatch({
          type: Types.SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  export const login = (username, password) => (dispatch) => {
    console.log("xxxx")
    return AuthService.login(username, password).then(
      (data) => {
        dispatch({
          type: Types.LOGIN_SUCCESS,
          payload: { user: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: Types.LOGIN_FAIL,
        });
  
        dispatch({
          type: Types.SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  export const loginFailure = () => (dispatch) => {
  
    dispatch({
        type: Types.LOGIN_FAIL
      });
  };

  export const logout = (token) => (dispatch) => {
    return AuthService.logout(token).then(
      (data) => {
        dispatch({
          type: Types.LOGOUT,
          payload: { user: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        
  
        return Promise.reject();
      }
    );
  };
