import { Types } from "../constants/actionTypes";
  
  const user = JSON.parse(localStorage.getItem("user"));
  
  const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case Types.LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user.payload,
        };
      case Types.LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case Types.LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      default:
        return state;
    }
  }