import { Types } from "../constants/actionTypes";
  
import AdminService from "../services/admin.service";
  
  
  export const submitReviewDetail = (username, password) => (dispatch) => {
    console.log("xxxx")
    return AdminService.submitReview(username, password).then(
      (data) => {
        if(data.status=="success"){
            dispatch({
              type: Types.REVIEW_SUBMITTED,
              payload: { reviews: data }
            });
            return Promise.resolve();
        }
    },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: Types.LOGIN_FAIL,
        });
  
        dispatch({
          type: Types.SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  export const saveScrapItem = (scrapItems) => (dispatch) => {
    console.log("xxxx")
    return AdminService.addScrapItem(scrapItems).then(
      (data) => {
        if(data.payload){
            dispatch({
              type: Types.ADD_SCRAP,
              payload: { reviews: data }
            });
            return Promise.resolve();
        }
    },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: Types.LOGIN_FAIL,
        });
  
        dispatch({
          type: Types.SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  export const markReviewDone = () => (dispatch) => {
    dispatch({
        type: Types.REVIEW_DONE,
        payload: { reviews: 'done' }
      });
  }
