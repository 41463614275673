import axios from "axios";
import { Config } from "../config";

const API_URL = "http://localhost:3001/api/";
const Authorization= localStorage.getItem('user')
var headers={};
if(Authorization){
  var headers = {
    'Content-Type': 'application/json',
    'Authorization': JSON.parse(Authorization).token
  }
}
const register = (username, email, password) => {
  return axios.post(API_URL + "login", {
    username,
    email,
    password,
  });
};

const submitReview = (username, password) => {
 
  return axios.post(Config.API_URL + "/api/submit/review", {
      username,
      password,
    })
    .then((response) => {
      return response.data;
    });
};

const addScrapItem = (scrapData) => {
  return axios.post(Config.API_URL + "/scrapRates", scrapData,{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}

export default {
    submitReview,
    addScrapItem
};