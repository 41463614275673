import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Header, Sidebar, Footer } from '../../components';
import {submitReviewDetail, markReviewDone} from '../../actions/admin';
import './style.css';
export class Messages extends Component {
  constructor(props) {
    super(props);
    this.addReview = this.addReview.bind(this);
    this.handleShow= this.handleShow.bind(this)
    this.handleClose= this.handleClose.bind(this)
    this.state = {
      reviewer_name: '',
      review_text: '',
      loginStatus: '',
      show: false,
      errors: {
        reviewer_name: 'Enter Name!',
        review_text: 'Enter Review!'
      },
      submitted: false
    }
  }
  
  inputChange = (event) => {
    const { name, value } = event.target; 
    this.setState({ [name]: value });
    this.validationErrorMessage(event);
  }
  validationErrorMessage = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'reviewer_name': 
        errors.reviewer_name = value.length < 1 ? 'Enter User Name' : '';
        break;
      case 'review_text': 
        errors.review_text = value.length < 1 ? 'Enter Password' : '';
        break;
      default:
        break;
    } 
    this.setState({ errors });
  }
  componentDidMount(){
    
  }
  validateForm = (errors) => {
    let valid = true; 
    Object.entries(errors).forEach(item => {
     
      item && item[1].length > 0 && (valid = false)
    }) 
    return valid;
  }

  submitReview = async (event) => {
    this.setState({ submitted: true });
    event.preventDefault();
    if (this.validateForm(this.state.errors)) {
      console.info('Valid Form')
      
      this.props.submitReviewDetail(this.state.review_text,this.state.reviewer_name);
    } else {
      console.log('Invalid Form')
    }
  }
  componentDidUpdate(){
    if(this.props.isLoggedIn==false){
      // this.props.history.push('/login')
    }
    if(this.props.reviewSubmitted==true){
      if(this.state.show==true){
        this.setState({show:false})
      }
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "Review has been added success fully"
      });
      this.props.markReviewDone();
    }
  }
  handleClose () { 
    this.setState({show:false});
  } 
  handleShow () { 
    this.setState({show:true}); 
  }


  addReview(){
    //console.log(this)
    this.StaticExample()
  }

  render() {
    const {reviewer_name, review_text, submitted, errors} = this.state;
    return (
        
        <div class="container-scroller">
        <Header/>
        <div class="container-fluid page-body-wrapper">
          <Sidebar/>
            <div class="container-fluid h-100">
                <div class="row justify-content-center h-100">
                    <div class="col-md-4 col-xl-3 chat"><div class="card mb-sm-3 mb-md-0 contacts_card">
                        <div class="card-header">
                            <div class="input-group">
                                <input type="text" placeholder="Search..." name="" class="form-control search"/>
                                <div class="input-group-prepend">
                                    <span class="input-group-text search_btn"><i class="fas fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body contacts_body">
                            <ui class="contacts">
                            <li class="active">
                                <div class="d-flex bd-highlight">
                                    <div class="img_cont">
                                        <img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" class="rounded-circle user_img"/>
                                        <span class="online_icon"></span>
                                    </div>
                                    <div class="user_info">
                                        <span>Khalid</span>
                                        <p>Kalid is online</p>
                                    </div>
                                </div>
                            </li>
                            
                            </ui>
                        </div>
                        <div class="card-footer"></div>
                    </div></div>
                    <div class="col-md-8 col-xl-9 chat">
                        <div class="card">
                            <div class="card-header msg_head">
                                <div class="d-flex bd-highlight">
                                    <div class="img_cont">
                                        <img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" class="rounded-circle user_img"/>
                                        <span class="online_icon"></span>
                                    </div>
                                    <div class="user_info">
                                        <span>Chat with Khalid</span>
                                        <p>1767 Messages</p>
                                    </div>
                                    <div class="video_cam">
                                        <span><i class="fas fa-video"></i></span>
                                        <span><i class="fas fa-phone"></i></span>
                                    </div>
                                </div>
                                <span id="action_menu_btn"><i class="fas fa-ellipsis-v"></i></span>
                                <div class="action_menu">
                                    <ul>
                                        <li><i class="fas fa-user-circle"></i> View profile</li>
                                        <li><i class="fas fa-users"></i> Add to close friends</li>
                                        <li><i class="fas fa-plus"></i> Add to group</li>
                                        <li><i class="fas fa-ban"></i> Block</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-body msg_card_body">
                                <div class="d-flex justify-content-start mb-4">
                                    <div class="img_cont_msg">
                                        <img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" class="rounded-circle user_img_msg"/>
                                    </div>
                                    <div class="msg_cotainer">
                                        Hi, how are you samim?
                                        <span class="msg_time">8:40 AM, Today</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end mb-4">
                                    <div class="msg_cotainer_send">
                                        Hi Khalid i am good tnx how about you?
                                        <span class="msg_time_send">8:55 AM, Today</span>
                                    </div>
                                    <div class="img_cont_msg">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start mb-4">
                                    <div class="img_cont_msg">
                                        <img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" class="rounded-circle user_img_msg"/>
                                    </div>
                                    <div class="msg_cotainer">
                                        I am good too, thank you for your chat template
                                        <span class="msg_time">9:00 AM, Today</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end mb-4">
                                    <div class="msg_cotainer_send">
                                        You are welcome
                                        <span class="msg_time_send">9:05 AM, Today</span>
                                    </div>
                                    <div class="img_cont_msg">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start mb-4">
                                    <div class="img_cont_msg">
                                        <img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" class="rounded-circle user_img_msg"/>
                                    </div>
                                    <div class="msg_cotainer">
                                        I am looking for your next templates
                                        <span class="msg_time">9:07 AM, Today</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end mb-4">
                                    <div class="msg_cotainer_send">
                                        Ok, thank you have a good day
                                        <span class="msg_time_send">9:10 AM, Today</span>
                                    </div>
                                    <div class="img_cont_msg">
                                    <img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" class="rounded-circle user_img_msg"/>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start mb-4">
                                    <div class="img_cont_msg">
                                        <img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" class="rounded-circle user_img_msg"/>
                                    </div>
                                    <div class="msg_cotainer">
                                        Bye, see you
                                        <span class="msg_time">9:12 AM, Today</span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <span class="input-group-text attach_btn"><i class="fas fa-paperclip"></i></span>
                                    </div>
                                    <textarea name="" class="form-control type_msg" placeholder="Type your message..."></textarea>
                                    <div class="input-group-append">
                                        <span class="input-group-text send_btn"><i class="fas fa-location-arrow"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Review</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form>
                <div class="form-group">
                  <label for="recipient-name" value={reviewer_name} class="col-form-label">Name:</label>
                  <input type="text" class="form-control" id="reviewer_name" name="reviewer_name" onChange={(e) => { this.inputChange(e)} }/>
                  { submitted && errors.reviewer_name.length > 0 &&  <span className='error'>{errors.reviewer_name}</span>}
                </div>
                <div class="form-group">
                  <label for="message-text" class="col-form-label">Review:</label>
                  <textarea class="form-control" value={review_text} id="review_text" name="review_text" onChange={(e) => { this.inputChange(e)} }></textarea>
                  { submitted && errors.review_text.length > 0 &&  <span className='error'>{errors.review_text}</span>}
                </div>
                <div class="form-group">
                  <label for="message-text" class="col-form-label">Photo:</label>
                  <input type="file" class="form-control" id="reviwer_photo"/>
                </div>
              </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.submitReview}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isLoggedIn,
  reviewSubmitted:state.admin.reviewSubmitted
});
const mapDispatchToProps = {
  submitReviewDetail,
  markReviewDone
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Messages));
